import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import mainImage from "../images/Main.png"

const Header = ({ siteTitle }) => (
  <header class="sticky-top">
    <nav class="navbar navbar-expand-lg navbar-light border-bottom border-dark sticky-top bg-white sticky-top">
      <div class="container-lg">
        <a class="navbar-brand me-0" href="#"></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav justify-content-center flex1">
            <li class="nav-item text-center"></li>
            <li class="nav-item text-center">
              <Link
                to="/"
                activeClassName="nav-link active"
                className="nav-link"
              >
                Home
              </Link>
            </li>
            <li class="nav-item text-center">
              <Link
                to="/blog"
                activeClassName="nav-link active"
                className="nav-link"
              >
                Blog
              </Link>
            </li>
            <li class="nav-item text-center">
              <Link
                to="/resources"
                activeClassName="nav-link active"
                className="nav-link"
              >
                Resources
              </Link>
            </li>
            <li class="nav-item text-center">
              <Link
                to="/testimonials"
                activeClassName="nav-link active"
                className="nav-link"
              >
                Testimonials
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
