/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "bootstrap/dist/css/bootstrap.css"
import "./main.css"
import Headericon from "./headericon"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Headericon />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <footer class="footer py-3 bg-light mt-5">
        <div class="container">
          <div class="row">
            <div class="col">
              <ul class="list-group list-group-flush text-center">
                <li class="list-group-item bg-light fw-bold">Contact</li>
                <li class="list-group-item bg-light">Paul@IGMobility.com</li>
              </ul>
            </div>
            <div class="col">
              <ul class="list-group list-group-flush text-center">
                <li class="list-group-item bg-light fw-bold">Connect</li>
                <li class="list-group-item bg-light border-0">
                  <a
                    target="_blank"
                    class="link-text"
                    href="https://www.linkedin.com/in/paulbarnes1/"
                  >
                    Paul Barnes LinkedIn
                  </a>
                </li>
                <li class="list-group-item bg-light border-0">
                  <a
                    target="_blank"
                    class="link-text"
                    href="https://www.linkedin.com/company/inspire-global-mobility-consulting/?viewAsMember=true"
                  >
                    Inspire Global Mobility LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div class="col">
              <ul class="list-group list-group-flush text-center">
                <li class="list-group-item bg-light fw-bold">
                  Privacy And Conditions
                </li>
                <li class="list-group-item bg-light border-0">
                  <Link to="/conditions" className="link-text">
                    Terms And Conditions
                  </Link>
                </li>
                <li class="list-group-item bg-light border-0">
                  <Link to="/privacy" className="link-text">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
