import * as React from "react"
import mainImage from "../images/Main.png"

const Headericon = ({ siteTitle }) => (
  <div class="container-flex bg-white">
    <img src={mainImage} height="80" class="middle" />
  </div>
)

export default Headericon
